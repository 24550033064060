.bg-radial-courses {
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("../../components/svg/CoursesRadial.svg");
  background-size: cover;
  width: 360px;
  height: 500px;
  left: -80px;
  margin-top: -220px;
}

@media (min-width: 1600px) {
  .bg-radial-courses {
    height: 570px;
    left: -50px;
    margin-top: -290px;
  }
}

@media (min-width: 1920px) {
  .bg-radial-courses {
    width: 400px;
    height: 770px;
    left: 0;
    margin-top: -492px;
  }
}
