.dangerouslySetInnerHTML {
  h1 {
    display: block;
    font-size: 40px;
    line-height: 44px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #0a0f3c;
  }

  h2 {
    display: block;
    font-size: 32px;
    line-height: 38px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #0a0f3c;
  }

  h3 {
    display: block;
    font-size: 28px;
    line-height: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #0a0f3c;
  }

  h4 {
    display: block;
    font-size: 20px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #0a0f3c;
  }

  h5 {
    display: block;
    font-size: 28px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #0a0f3c;
  }

  h6 {
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #0a0f3c;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #3f404d;
  }

  a {
    color: #1930ff;
    cursor: pointer;
  }

  ul {
    list-style: inside;
    li {
      color: #3f404d;
      font-size: 16px;
      line-height: 24px;
    }
    li::marker {
      font-size: 24px;
      line-height: 20px;
      color: #1930ff;
    }
  }

  ol {
    list-style-type: decimal;
    li {
      font-size: 16px;
      color: #3f404d;
    }
  }

  div {
    font-size: 16px;
    color: #3f404d;
  }

  .customTable {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    table {
      width: 100%;
      border-collapse: collapse;
      color: #3f404d;

      th {
        font-weight: bold;
        text-align: left;
        color: #0a0f3c;
      }
      tr {
        border-bottom: 1px solid #c5cbff;
        td > p {
          text-align: left;
          padding: 12px 0;
        }
      }
    }
  }

  .checklist {
    list-style-image: url("../images/check.svg");
  }

  .text-green {
    color: #00e8cc;
    font-weight: bold;
  }

  .MsoNormal > hr {
    border-top: 1px solid #c5cbff;
  }
}
