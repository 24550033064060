.customRightShape {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #dde0ff;
  border-left: 10px solid #1930ff;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 60%, 0 60%);
}

.customLeftShape {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #dde0ff;
  border-left: 10px solid #1930ff;
  clip-path: polygon(0 0, 100% 0%, 100% 60%, 0 100%, 0 100%);
}

@media (max-width: 1024px) {
  .customRightShape {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    border-left: 5px solid #1930ff;
  }
  .customLeftShape {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    border-left: 5px solid #1930ff;
  }
}
