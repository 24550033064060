@import "./assets/scss/homepage.scss";
@import "./assets/scss/hero.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Roboto, Arial;
}

.custom-border > div:last-child > div:nth-child(2) {
  border-left: 1px solid #dde0ff;
  border-radius: 0;
}

.custom-border > div:first-child > div:nth-child(2) {
  border-right: 1px solid #dde0ff;
  border-radius: 0;
}

.textEllipsis1Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.textEllipsis2Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textEllipsis3Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.textEllipsis4Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card-hover:hover .hover-img {
  transform: scale(1.1);
}
.card-hover:hover .read-more-blue-hover {
  color: #162be5;
  border-bottom: 2px solid #162be5;
}

.read-more-btn:hover .read-more-blue-hover {
  color: #162be5;
  border-bottom: 2px solid #162be5;
}

.read-more-btn:hover .read-more-white-hover {
  border-bottom: 2px solid #ffffff;
}

.read-more-blue-hover {
  border-bottom: 2px solid transparent;
}

.read-more-white-hover {
  border-bottom: 2px solid transparent;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -6px);
}

.swiper-button-prev,
.swiper-button-next {
  --swiper-navigation-size: 18px;
}
