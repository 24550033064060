.item1 {
  grid-area: a1;
}

.item2 {
  grid-area: a2;
}

.item3 {
  grid-area: a3;
}

.item4 {
  grid-area: a4;
}

.item5 {
  grid-area: a5;
}

.item6 {
  grid-area: a6;
}


.item7 {
  grid-area: a7;
}

.item1,
.item2,
.item3,
.item4,
.item5,
.item6,
.item7,
.carouselItem {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-container> div {
  background-color: rgba(180, 180, 180, 0.25);
  text-align: center;
  font-size: 30px;
}

.grid-container {
  height: 100%;
  display: grid;
  grid-template:
    'a1 a1 a2'
    'a1 a1 a2'
    'a1 a1 a3'
    'a1 a1 a3'
    'a4 a5 a3'
    'a4 a5 a3'
    'a4 a7 a7'
    'a6 a7 a7'
    'a6 a7 a7';
  grid-gap: 20px;
  background-color: #FFFFFF;
}
