.font-36 {
  font-size: 36px;
}

.grid-template {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.business-specific-container {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.business-specific-container strong {
  font-size: 40px;
  line-height: 46px;
}

.book-consultation-container p:nth-child(1) {
  font-size: 40px;
  font-style: normal;
  line-height: 44px;
  color: #0a0f3c;
  margin-bottom: 30px;
}

.book-consultation-container p:nth-child(2) {
  font-size: 30px;
  line-height: 34px;
  color: #3f404d;
  font-weight: 400;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .grid-template {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .blue-background:before {
    content: "";
    display: block;
    width: 100%;
    min-height: 400px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 375 333'><path d='M0 74L375 0V332.5H187.5H100C44.7715 332.5 0 287.728 0 232.5V74Z' fill='%231930FF'/></svg>");
    background-size: cover;
    position: absolute;
    top: 380px;
    right: 0px;
  }
}

.bg-image {
  background-image: url("../../components//svg/RadialMaskBlueRect.svg");

  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1024px) {
  .bg-image {
    background-image: url("../../components//svg/MobileRadialMaskBlueRect.svg");
  }

  .book-consultation-container p:nth-child(1) {
    font-size: 25px;
    line-height: 30px;
    color: #0a0f3c;
  }

  .book-consultation-container p:nth-child(2) {
    font-size: 20px;
    line-height: 26px;
    color: #3f404d;
    font-weight: 400;
  }

  .business-specific-container {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .business-specific-container strong {
    font-size: 25px;
    line-height: 30px;
  }
}
