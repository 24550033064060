@media screen and (max-width: 1200px) {
  .items-menu {
    span {
      font-size: 12px;
    }
  }

  button {
    display: flex;
    word-break: keep-all;
    padding-block: 0;

    span {
      white-space: nowrap;
      margin-block: 3px;
    }

    * {
      font-size: 12px;
    }
  }
}

.dropdown-content {
  position: absolute;
  display: flex !important;
  padding-bottom: 15px;
}

.dropdown-content {
  position: absolute;
  display: none;
  margin-top: -1px;
  min-width: 160px;
  z-index: 0;
}

.dropdown-items {
  border-radius: 2px 2px 20px 20px;
}

.language-width {
  min-width: 200px !important;
}
